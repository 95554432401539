var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-section',{staticClass:"white",attrs:{"id":"k-d-a-careers-looks","space":"80","top-inner-shadow":""}},[_c('v-container',{staticClass:"pa-0 px-7"},[_c('v-row',{ref:"looksContainerRef",staticStyle:{"gap":"16px"},attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{class:`pb-${_vm.g_bLowerBr ? '8' : '15'}`,attrs:{"cols":"12"}},[_c('h1',{class:`kda-ts-${_vm.g_bLowerBr ? '28' : '46'}pt wt-extrabold`},[_vm._v(_vm._s(_vm.m_strTitle))])]),_vm._l((_vm.m_arrLooks),function(lookContainer,containerIndex){return _c('v-col',{key:`looks-container-${containerIndex}`,attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mx-auto",style:({
            width: _vm.m_iLooksContainerWidth ? `${_vm.m_iLooksContainerWidth}px` : '100%'
          }),attrs:{"justify":lookContainer[0].direction === 'left' ? 'end' : 'start',"align":"center"}},_vm._l((lookContainer),function(looks){return _c('v-col',{key:looks.strTitle,ref:"lookColRefs",refInFor:true,class:`d-flex ${
              looks.position === 'bottom'
                ? 'align-self-start flex-column-reverse'
                : 'align-self-end flex-column'
            }`,attrs:{"cols":"auto"}},[(!looks.noArrow)?_c('img',{class:`m${looks.position === 'bottom' ? 't' : 'b'}-4`,style:({
                maxWidth: '86px',
                alignSelf:
                  looks.shortArrow || looks.direction === 'down'
                    ? 'center'
                    : looks.direction == 'left'
                    ? 'start'
                    : 'end'
              }),attrs:{"src":_vm.getArrowImgSrc(looks)}}):_vm._e(),_c('v-card',{staticClass:"pa-4 text-center",staticStyle:{"border":"1px solid #2f2f2f"},attrs:{"flat":"","rounded":"xl","width":"140"}},[_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"110px","width":"110px"}},[_c('img',{attrs:{"src":looks.imgSrc}})]),_c('h4',{staticClass:"mt-8 kda-ts-12pt nunito wt-extrabold"},[_vm._v(_vm._s(looks.strTitle))]),_c('div',{staticClass:"main-bw-400 my-3",staticStyle:{"height":"1px","width":"100%"}}),_c('p',{staticClass:"kda-ts-12pt nunito ln-170"},[_vm._v(_vm._s(looks.strDesc))])])],1)}),1)],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }