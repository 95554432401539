<template>
  <base-section id="k-d-a-careers-looks" class="white" space="80" top-inner-shadow>
    <v-container class="pa-0 px-7">
      <v-row ref="looksContainerRef" align="center" justify="center" no-gutters style="gap: 16px">
        <v-col cols="12" :class="`pb-${g_bLowerBr ? '8' : '15'}`">
          <h1 :class="`kda-ts-${g_bLowerBr ? '28' : '46'}pt wt-extrabold`">{{ m_strTitle }}</h1>
        </v-col>

        <v-col
          v-for="(lookContainer, containerIndex) in m_arrLooks"
          :key="`looks-container-${containerIndex}`"
          cols="12"
        >
          <v-row
            :justify="lookContainer[0].direction === 'left' ? 'end' : 'start'"
            align="center"
            class="mx-auto"
            :style="{
              width: m_iLooksContainerWidth ? `${m_iLooksContainerWidth}px` : '100%'
            }"
          >
            <v-col
              v-for="looks in lookContainer"
              :key="looks.strTitle"
              ref="lookColRefs"
              cols="auto"
              :class="`d-flex ${
                looks.position === 'bottom'
                  ? 'align-self-start flex-column-reverse'
                  : 'align-self-end flex-column'
              }`"
            >
              <img
                v-if="!looks.noArrow"
                :src="getArrowImgSrc(looks)"
                :style="{
                  maxWidth: '86px',
                  alignSelf:
                    looks.shortArrow || looks.direction === 'down'
                      ? 'center'
                      : looks.direction == 'left'
                      ? 'start'
                      : 'end'
                }"
                :class="`m${looks.position === 'bottom' ? 't' : 'b'}-4`"
              />

              <v-card
                class="pa-4 text-center"
                flat
                style="border: 1px solid #2f2f2f"
                rounded="xl"
                width="140"
              >
                <div class="d-flex justify-center align-center" style="height: 110px; width: 110px">
                  <img :src="looks.imgSrc" />
                </div>

                <h4 class="mt-8 kda-ts-12pt nunito wt-extrabold">{{ looks.strTitle }}</h4>

                <div class="main-bw-400 my-3" style="height: 1px; width: 100%"></div>

                <p class="kda-ts-12pt nunito ln-170">{{ looks.strDesc }}</p>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDACareersLooks',

  mixins: [BaseBreakpoint],

  computed: {
    m_strTitle() {
      return this.$vuetify.lang.t('$vuetify.kda.careers.looks.title');
    },
    m_arrLooks() {
      const origin = [];

      for (let i = 1; i <= 5; i++) {
        origin.push({
          strTitle: this.$vuetify.lang.t(`$vuetify.kda.careers.looks.look${i}.title`),
          strDesc: this.$vuetify.lang.t(`$vuetify.kda.careers.looks.look${i}.desc`),
          imgSrc: require(`@/assets/photos/careers-look-${i}.png`)
        });
      }

      const result = [];

      if (this.g_bLowerBr) {
        origin.forEach((item, index) => {
          result.push([item]);

          result[index][0].shortArrow = true;
        });

        result[0][0].noArrow = true;
      } else {
        let subt = origin.length;

        if (this.g_bBaseBr) {
          --subt;
          --subt;
        }

        result.push(origin.slice(0, subt));

        if (subt < origin.length) {
          result.push(origin.slice(subt, origin.length).reverse());
        }

        const isEven = (val) => val % 2 === 0;
        const changePosition = (i, j, determinator) =>
          (result[i][j].position = isEven(determinator) ? 'bottom' : 'top');

        let positionDeterminator = 0;
        let i = 0;
        let j = 0;

        for (i = 0; i < result.length; i++) {
          if (isEven(i)) {
            for (j = 0; j < result[i].length; j++) {
              result[i][j].direction = 'right';
              changePosition(i, j, positionDeterminator++);
            }

            j--;
          } else {
            for (j = result[i].length - 1; j >= 0; j--) {
              result[i][j].direction = 'left';
              changePosition(i, j, positionDeterminator++);
            }
            j++;
          }

          if (i === result.length - 1) {
            result[i][j].noArrow = true;
          } else {
            if (result[i][j].position === 'bottom') {
              result[i][j].direction = 'down';
            } else if (result[i][j].position === 'top') {
              result[i][j].noArrow = true;
            }
          }

          positionDeterminator--;
        }
      }

      return result;
    },
    m_iLooksContainerWidth() {
      let mux = 5;

      if (this.g_bBaseBr) {
        --mux;
        --mux;
      }

      if (this.g_bLowerBr) {
        mux = 1;
      }

      return (this.$refs.lookColRefs?.[0]?.clientWidth ?? 164) * mux;
    }
  },

  methods: {
    getArrowImgSrc(looks) {
      return require(`@/assets/icons/careers-look-${
        looks.shortArrow
          ? 'down-short'
          : looks.direction === 'down'
          ? 'down'
          : looks.direction + '-' + looks.position
      }.png`);
    }
  }
};
</script>
